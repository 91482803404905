import { BatchesByCommunity } from "../types";

export const getDefaultCommunity = (communityBatches: BatchesByCommunity) =>
  communityBatches
    ? {
        name: Object.keys(communityBatches)?.[0],
        id: communityBatches?.[Object.keys(communityBatches)?.[0]]?.id,
      }
    : undefined;

export const getDefaultLeaseEndDate = (
  communityBatches: BatchesByCommunity,
  community?: string
) =>
  community
    ? {
        leaseEndDate:
          communityBatches?.[community]?.batches?.[0]?.leaseEnd.label,
        month: communityBatches?.[community]?.batches?.[0]?.month,
        year: communityBatches?.[community]?.batches?.[0]?.year,
      }
    : undefined;

export const getBatchIdForCommunity = (
  communityBatches: BatchesByCommunity,
  leaseEndDate: string,
  community?: string
) => {
  if (!community || !communityBatches?.[community]) return undefined;

  if (leaseEndDate) {
    return communityBatches[community].batches.find(
      (batch) => batch.leaseEnd.label === leaseEndDate
    )?.id;
  }

  return communityBatches[community]?.batches[0]?.id;
};
