import { useEffect, useState } from "react";
import { IndividualBatch } from "../types";

const useBatchProcessor = (batches: IndividualBatch[] | undefined) => {
  const [batchesByCommunity, setBatchesByCommunity] = useState<
    { [key: string]: { id: string; batches: IndividualBatch[] } } | undefined
  >();

  useEffect(() => {
    if (batches) {
      setBatchesByCommunity(
        batches.reduce(
          (
            accumulator: {
              [key: string]: { id: string; batches: IndividualBatch[] };
            },
            batch
          ) => {
            const state = accumulator;
            if (!state[batch.community]) {
              state[batch.community] = {
                id: "",
                batches: [],
              };
            }
            state[batch.community].id = batch.communityId;
            state[batch.community].batches.push(batch);
            return state;
          },
          {}
        )
      );
    }
  }, [batches]);

  return { batchesByCommunity };
};

export default useBatchProcessor;
