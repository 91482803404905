import { ElementType } from "react";
import * as MuiIcons from "@mui/icons-material";

const getDynamicIcon = (iconName: string): ElementType | null => {
  const cleanIconName = iconName.replace(/Icon$/, "");
  const IconComponent = (MuiIcons as Record<string, ElementType>)[
    cleanIconName
  ];
  return IconComponent || null;
};

export default getDynamicIcon;
