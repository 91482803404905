import React from "react";
import MetricCard from "./MetricCard";
import * as Styled from "./styles";
import useConnect from "./connect";

const Metrics = () => {
  const { metrics } = useConnect();

  return (
    <Styled.Grid>
      {metrics?.map((metric) => (
        <MetricCard
          key={`metric-${metric.title}`}
          icon={metric.icon ? React.createElement(metric.icon) : null}
          title={metric.title}
          metric={metric.metric}
          tooltip={metric.tooltip}
        />
      ))}
    </Styled.Grid>
  );
};

export default Metrics;
