import {
  CompletedStepIcon,
  ActiveStepIcon,
  ActiveStepDot,
  InactiveStepIcon,
  StepIcon,
} from "./styles";

interface CustomStepIconProps {
  active: boolean;
  completed: boolean;
}

const CustomStepIcon = ({ active, completed }: CustomStepIconProps) => {
  if (completed) {
    return (
      <CompletedStepIcon>
        <StepIcon />
      </CompletedStepIcon>
    );
  }

  if (active) {
    return (
      <ActiveStepIcon>
        <ActiveStepDot />
      </ActiveStepIcon>
    );
  }

  return <InactiveStepIcon />;
};

export default CustomStepIcon;
