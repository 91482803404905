import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Body1, StyledLink } from "@/components/Typography";
import { RenewalOfferWorkflowStatus, DocumentType } from "@/graphql/generated";
import {
  CheckCircleOutline,
  Checklist,
  HelpOutline,
} from "@mui/icons-material";
import ToolTip from "@/components/Tooltip";

import useDownloadUrl from "@/graphql/hooks/downloadUrl/useDownloadUrl";
import { withoutNulls } from "@/utils/withoutNulls";
import showSnackbar from "@/utils/showSnackbar";
import { RowType } from "../StatusTables/types";
import * as Styled from "./styles";
import useConnect from "./connect";
import RenewalTimeline from "./RenewalTimeline";

interface DetailDrawerProps {
  renewalOffer?: RowType;
}

const DetailDrawer: React.FC<DetailDrawerProps> = ({ renewalOffer }) => {
  const { downloadUrl } = useDownloadUrl({});

  const downloadLink = React.useCallback(
    async (
      id?: string
      // shouldDownload = false
    ) => {
      const url = downloadUrl({
        objectId: id,
        objectType: DocumentType.RenewalOffer,
      });
      const downloadData = withoutNulls((await url)?.data?.downloadablePdfUrl);
      if (
        downloadData?.__typename === "DownloadablePdfUrl" &&
        downloadData?.url
      ) {
        // ---- add back in as a quick follow up when BE updates are made ----
        // if (shouldDownload) {
        //   // Update with url for download when availagble from backend
        //   window.open(downloadData?.url);
        // } else {
        window.open(downloadData?.url, "_blank");
        // }
      }
    },
    [downloadUrl]
  );

  const onAction = (
    id?: string
    // shouldDownload = false
  ) => {
    downloadLink(
      id
      // shouldDownload
    ).catch(() => {
      showSnackbar(
        "There has been an error with your PDF. Please try again later.",
        "error"
      );
    });
  };

  const { open, activityItems, unitName, onClose, unit, status } =
    useConnect(renewalOffer);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "740px",
            boxSizing: "border-box",
            borderRadius: "8px 0 0 8px",
          },
        }}
      >
        <Styled.DrawerHeader>
          <Styled.HeaderTextContainer>
            <Body1 $size="lg" $weight="bold">
              {unit?.communityName}
            </Body1>
            <Body1 $size="sm" $weight="regular" $color="grey600">
              {unitName}
              <StyledLink
                $size="sm"
                $weight="medium"
                $underlined
                $inline
                $color="night400"
                style={{ marginLeft: "8px" }}
                href={`/units/${unit?.id || ""}`}
              >
                View unit profile
              </StyledLink>
            </Body1>
            <Styled.StatusPill
              $size="xs"
              $weight="medium"
              $bgColor={status.color}
            >
              {renewalOffer?.workflowStatus ===
                RenewalOfferWorkflowStatus.Completed && (
                <CheckCircleOutline sx={{ height: "12px", width: "12px" }} />
              )}
              {status.title}
            </Styled.StatusPill>
          </Styled.HeaderTextContainer>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Styled.PDFDownload $size="xs" $weight="medium">
              Renewal offer PDF
            </Styled.PDFDownload>
            {/* <button
              type="button"
              style={{
                cursor: "pointer",
                border: "none",
                background: "none",
              }}
              onClick={() => onAction(renewalOffer?.id, true)}
            >
              {Styled.DownloadIcon}
            </button> */}
            <button
              type="button"
              style={{
                cursor: "pointer",
                border: "none",
                background: "none",
              }}
              onClick={() =>
                onAction(
                  renewalOffer?.id
                  // false
                )
              }
            >
              {Styled.ViewIcon}
            </button>

            <IconButton
              onClick={onClose}
              edge="end"
              aria-label="close"
              sx={{ top: "-10px" }}
            >
              <Styled.CloseIcon />
            </IconButton>
          </div>
        </Styled.DrawerHeader>

        <Styled.DrawerContent>
          <RenewalTimeline status={renewalOffer?.workflowStatus} />
          <Styled.ContentHeaderContainer>
            <Checklist sx={{ height: "12px", width: "12px" }} />
            <Body1 $size="sm" $weight="bold">
              Renewal Activity
            </Body1>
          </Styled.ContentHeaderContainer>
          {activityItems ? (
            activityItems
              .filter((item) => item?.title)
              .map((item) =>
                item?.title ? (
                  <Styled.DetailSection key={item.title}>
                    <Styled.DetailItem>
                      <ToolTip popperContent={item.tooltip || ""}>
                        <Styled.RenewalActivityItem $size="sm">
                          {item.title}
                          <HelpOutline />
                        </Styled.RenewalActivityItem>
                      </ToolTip>
                    </Styled.DetailItem>
                    <Body1 $size="sm" $weight="medium">
                      {item.value}
                    </Body1>
                  </Styled.DetailSection>
                ) : null
              )
          ) : (
            <Typography variant="body1">No data available</Typography>
          )}
        </Styled.DrawerContent>
      </Drawer>
    </>
  );
};

export default DetailDrawer;
