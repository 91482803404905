import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { formatDate } from "@/utils/formatDate";
import {
  RenewalOfferDecisionType,
  RenewalOfferWorkflowStatus,
} from "@/graphql/generated";
import { RowType } from "../StatusTables/types";

type ActivityItem = {
  title: string;
  value: string | undefined;
  tooltip: string;
};

function getStatus(
  offerDecision: RenewalOfferDecisionType | undefined,
  workflowStatus: RenewalOfferWorkflowStatus | undefined
): { title: string; color: string } {
  if (!offerDecision) {
    return { title: "No decision", color: "grey" };
  }

  if (
    offerDecision === RenewalOfferDecisionType.Interested ||
    offerDecision === RenewalOfferDecisionType.InterestedLate
  ) {
    return workflowStatus === RenewalOfferWorkflowStatus.Completed
      ? { title: "Renewal complete", color: "green" }
      : { title: "Submitted renewal", color: "green" };
  }

  if (offerDecision === RenewalOfferDecisionType.Declined) {
    return workflowStatus === RenewalOfferWorkflowStatus.Completed
      ? { title: "Notice to vacate finalized", color: "red" }
      : { title: "Submitted notice", color: "red" };
  }

  return { title: "No decision", color: "grey" };
}

export const useConnect = (renewalOffer?: RowType) => {
  const [open, setOpen] = useState(false);
  const [activityItems, setActivityItems] = useState<ActivityItem[]>([]);
  const router = useRouter();
  const { query, pathname } = router;

  const status = getStatus(
    renewalOffer?.decision.type,
    renewalOffer?.workflowStatus
  );

  const unitName = renewalOffer?.unit
    ? `Unit ${
        renewalOffer.unit?.building ? `${renewalOffer.unit?.building} - ` : ""
      }${renewalOffer.unit?.name || ""}`
    : "";

  useEffect(() => {
    const profileInfo = renewalOffer?.renewalProfileInfo;
    const items = [
      {
        title: "Survey Response: Likelihood to Renew",
        tooltip:
          "Self-reported likelihood to renew by resident(s) in the unit. This survey is collected 6 months prior to lease expiry.",
        value: profileInfo?.likelihoodToRenew?.length
          ? `${profileInfo.likelihoodToRenew
              .map((item) => `${item.residentName}: ${item.value}`)
              .join(", ")}`
          : "N/A",
      },
      {
        title: "Most Recent Resident Action",
        tooltip: "The most recent action a resident made.",
        value: profileInfo?.residentActions?.length
          ? profileInfo?.residentActions?.[0]?.name
          : "N/A",
      },
      {
        title: "Last Reminder Sent",
        tooltip:
          "The most recent date Renew sent a reminder (email/SMS) to encourage a decision.",
        value: profileInfo?.lastReminderSentAt
          ? formatDate(profileInfo?.lastReminderSentAt)
          : "N/A",
      },
      {
        title: "Last Resident Login",
        tooltip: "The date of the most recent Renew login.",
        value: renewalOffer?.determinedStatus?.lastViewedAt
          ? formatDate(renewalOffer?.determinedStatus?.lastViewedAt)
          : "N/A",
      },
      {
        title: "Last Viewed Renewal Offers",
        tooltip:
          "The most recent date a resident in unit viewed their renewal offers in Renew.",
        value: renewalOffer?.determinedStatus?.lastViewedAt
          ? formatDate(renewalOffer?.determinedStatus?.lastViewedAt)
          : "N/A",
      },
    ];

    setActivityItems(items);
  }, [renewalOffer]);

  useEffect(() => {
    // Check if offerId exists in the URL query
    if (query.offerId && renewalOffer) {
      setOpen(true);
    }
  }, [query.offerId, renewalOffer]);

  const onClose = () => {
    setOpen(false);

    // Remove offerId from the query parameters
    const newQuery = { ...query };
    delete newQuery.offerId;

    // Update the URL without the offerId parameter
    void router.push(
      {
        pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  return {
    open,
    status,
    unitName,
    activityItems,
    unit: renewalOffer?.unit,
    onClose,
  };
};

export default useConnect;
