import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";
import Check from "@mui/icons-material/Check";

export const CompletedStepIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${defaultTheme.renewTheme.colors.cobalt400};
  color: ${defaultTheme.renewTheme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActiveStepIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${defaultTheme.renewTheme.colors.cobalt400};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActiveStepDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${defaultTheme.renewTheme.colors.cobalt400};
`;

export const InactiveStepIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${defaultTheme.renewTheme.colors.grey300};
`;

export const StepIcon = styled(Check)`
  color: ${defaultTheme.renewTheme.colors.white};
  width: 12px;
  height: 12px;
`;
