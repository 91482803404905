import styled from "@emotion/styled";

// eslint-disable-next-line import/prefer-default-export
export const IconContainer = styled("div")<{
  background?: boolean;
  border?: boolean;
  backgroundColor?: string;
}>(({ background, backgroundColor }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  ...(background && {
    backgroundColor,
    borderRadius: "4px",
    padding: "4px",
  }),
}));
