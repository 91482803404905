// useRenewalStyles.js
import styled from "@emotion/styled";
import { Box, StepConnector, StepLabel } from "@mui/material";
import defaultTheme from "@/styles/themes/default";

export const Container = styled(Box)`
  width: 100%;
  margin: 1rem 20px;
  display: flex;
  .MuiStepper-horizontal {
    justify-content: space-between;
    width: 675px;
  }

  .MuiStep-horizontal {
    max-width: 40px;
    height: 40px;
    position: relative;
  }
`;

export const CustomStepLabel = styled(StepLabel)`
  & .MuiStepLabel-label {
    color: ${defaultTheme.renewTheme.colors.grey600};
  }
  & .MuiStepLabel-label.Mui-completed,
  & .MuiStepLabel-label.Mui-active {
    color: ${defaultTheme.renewTheme.colors.cobalt400};
  }

  & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
`;

export const stepIconStyles = {
  color: "#E0E0E0",
  "&.Mui-completed": {
    color: "#1A237E",
  },
  "&.Mui-active": {
    color: "#1A237E",
  },
};

export const CustomConnectorStyles = {
  alternativeLabel: {
    top: 16,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#E0E0E0",
    borderRadius: 1,
  },
  top: "10px",
  zIndex: -100000,
  left: "calc(-50% + 10px)",
  right: "calc(50% + 10px)",
};

export const BullseyeIconStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  borderRadius: "50%",
  border: "2px solid #1A237E",
  "&::after": {
    content: '""',
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#1A237E",
  },
};

export const CustomConnector = styled(StepConnector)`
  & .MuiStepConnector-line {
    height: 1px;
    border: 0;
    background-color: ${defaultTheme.renewTheme.colors.grey400};
    border-radius: 1px;
  }
  top: 10px;
  z-index: -100000;
  left: calc(-50% + -109px);
  right: calc(50% + 10px);
`;
