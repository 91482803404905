import styled from "@emotion/styled";
import PageHeader from "@/components/PageHeader";
import UtilizeSpacer from "@/styles/theme-styles";
import { Link, TextField } from "@mui/material";
import Table from "@/components/TableV2";
import DefaultCard from "@/components/Card";
import defaultTheme from "@/styles/themes/default";
import Sent from "@/assets/images/svgs/Sent";
import Complete from "@/assets/images/svgs/Complete";
import { Text } from "@/components/Typography";
import Pill from "@/components/Pill";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import getDynamicIcon from "@/utils/iconHelper";

export const Container = styled.div``;

const Arrow = getDynamicIcon("ArrowRightIcon");

export const ArrowIcon = Arrow
  ? styled(Arrow as React.ComponentType)`
      height: 12px;
      width: 12px;
    `
  : null;

export const CellLink = styled(Link)<{ $notUnderlined?: boolean }>`
  text-decoration: underline;
  color: ${defaultTheme.renewTheme.colors.night400};
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  ${({ $notUnderlined }) =>
    $notUnderlined &&
    `
    text-decoration: none;
  `}
`;

export const Header = styled(PageHeader)`
  ${UtilizeSpacer({
    options: [
      {
        size: "sm",
        direction: [3],
      },
      {
        size: "3xs",
        direction: [0],
      },
    ],
    type: "padding",
  })}
`;

export const SearchBox = styled(TextField)`
  background-color: ${defaultTheme.renewTheme.colors.white};
  .MuiInputBase-root {
    border-radius: 8px;
  }
`;

export const FilterItem = styled(Pill)``;

export const EmptyStateCard = styled(DefaultCard)`
  background-color: ${defaultTheme.renewTheme.colors.arctic25};
  justify-content: center;
  max-width: 100%;
  width: 100%;
  ${UtilizeSpacer({
    specificOption: {
      size: "2xs",
      direction: "bottom",
    },
    type: "padding",
  })}
`;

export const Action = styled.div`
  padding: 4px 8px;
  background-color: ${defaultTheme.renewTheme.colors.warning400};
  border-radius: 1rem;
  width: fit-content;
`;

export const SentIcon = styled(Sent)`
  height: 100% !important;
  width: 100% !important;
  min-height: 26px !important;
  min-width: 26px !important;
`;

export const CompleteIcon = styled(Complete)`
  height: 100% !important;
  width: 100% !important;
  min-height: 29px !important;
  min-width: 29px !important;
`;

export const Highlight = styled(Text)<{ $type: "messages" | "actions" }>`
  border-radius: 4px;
  margin-left: 8px;
  padding: 8px;
  min-width: 86px;
  color: ${defaultTheme.renewTheme.colors.black};
  display: flex;
  justify-content: center;

  ${({ $type }) =>
    $type === "messages"
      ? `
    background-color: ${defaultTheme.renewTheme.colors.arctic400};
  `
      : `
    background-color: ${defaultTheme.renewTheme.colors.warning400};
  `}
`;

export const NoOffersCard = styled(DefaultCard)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${UtilizeSpacer({
    specificOption: {
      size: "sm",
      direction: "left",
    },
    type: "margin",
  })}
  p {
    color: ${defaultTheme.renewTheme.colors.grey600};
    font-family: "Mint Grotesk";
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const StatusPillWrapper = styled(Link)`
  display: flex;
  gap: 4px;
  text-decoration: none;
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: 4px;
  text-decoration: none;
  color: ${defaultTheme.renewTheme.colors.grey600};
  align-items: center;
`;

export const StatusPill = styled.div<{ $type: "messages" | "actions" }>`
  padding: 4px 8px;
  border-radius: 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  color: ${defaultTheme.renewTheme.colors.grey600};
  font-weight: ${defaultTheme.renewTheme.weights.medium};
  font-size: ${defaultTheme.renewTheme.spacing.mobile["2xs"]};
  gap: 4px;
  ${({ $type }) =>
    $type === "messages"
      ? `
    background-color: ${defaultTheme.renewTheme.colors.arctic400};
  `
      : `
    background-color: ${defaultTheme.renewTheme.colors.warning400};
  `};
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  height: 10px;
  width: 10px;
`;

export const AttachEmailIcon = styled(AttachEmailOutlinedIcon)`
  height: 10px;
  width: 10px;
`;

export const ResolvedIcon = styled(MarkEmailReadOutlinedIcon)`
  height: 10px;
  width: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const AttachEmailIconStandaloneWrapper = styled.div<{
  $resolved?: boolean;
}>`
  padding: 4px 8px;
  border-radius: 1rem;
  background-color: ${defaultTheme.renewTheme.colors.success25};
  ${({ $resolved }) =>
    !$resolved &&
    `
    background-color: ${defaultTheme.renewTheme.colors.arctic400};
  `};
`;

export const Grid = styled(Table)`
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

export const OfflineIcon = styled(MobileOffIcon)`
  height: 9px;
  width: 9px;
  margin-right: 4px;
`;
