import useSegmentTrackEvent from "@/hooks/useSegmentTrackEvent";
import { buildDropDownFilterPayload } from "@/utils/buildDropDownFilterPayload";
import { Value, IndividualBatch } from "../types";

const useFilterAnalytics = (
  batchesByCommunity:
    | { [key: string]: { id: string; batches: IndividualBatch[] } }
    | undefined
) => {
  const { callTrack } = useSegmentTrackEvent();

  const filterChangeTrackCall = (
    value: Value,
    filterChangeUpdate: {
      community: string | undefined;
      leaseEndDate: string | undefined;
      batchId: string | undefined;
      month: string | undefined;
      year: string | undefined;
      communityId: string | undefined;
    }
  ) => {
    const payloadConfig = {
      community: {
        filterName: "community",
        filterOptions: batchesByCommunity && Object.keys(batchesByCommunity),
        selectedFilters: [filterChangeUpdate.community],
      },
      leaseEnd: {
        filterName: "leaseEndDate",
        filterOptions:
          filterChangeUpdate.community &&
          batchesByCommunity?.[filterChangeUpdate.community].batches.map(
            (batch) => batch.leaseEnd.label
          ),
        selectedFilters: [filterChangeUpdate.leaseEndDate],
      },
    };

    const selectedFilter = value.community ? "community" : "leaseEnd";

    const payload = buildDropDownFilterPayload({
      // when a user selects from the leaseEndDate dropdown, the selected value does not contain community
      selected: value.community || value.leaseEndDate,
      active: true,
      filterName: payloadConfig[selectedFilter].filterName,
      filterOptions: payloadConfig[selectedFilter].filterOptions,
      selectedFilters: payloadConfig[selectedFilter].selectedFilters,
      filterGroupName: "RMS Community Batch Selection",
      filterGroupState: {
        leaseEndDate: [filterChangeUpdate.leaseEndDate],
        community: [filterChangeUpdate.community],
      },
    });

    callTrack({ eventName: "filter.click", payload });
  };

  return { filterChangeTrackCall };
};

export default useFilterAnalytics;
