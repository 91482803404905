/* eslint-disable no-param-reassign */
import { useContext } from "react";
import { RenewalOfferContext } from "@/context/renewal-offer/context";
import { Metric } from "@/graphql/generated";
import getDynamicIcon from "@/utils/iconHelper";
import { camelCaseToSentence } from "@/utils/camelToSentenceCase";

export default function useConnect() {
  const { renewalOfferState } = useContext(RenewalOfferContext);

  const { metrics } = renewalOfferState;

  const adjustedMetrics = metrics?.map((metric: Metric) => {
    const IconComponent = getDynamicIcon(metric.icon);
    return {
      icon: IconComponent,
      title: camelCaseToSentence(metric.name),
      metric: metric.value,
      tooltip: metric.tooltipCopy,
    };
  });

  return {
    metrics: adjustedMetrics,
  };
}
