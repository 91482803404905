import InboxIcon from "@mui/icons-material/Inbox";

const InboxActive = ({
  className,
  inactive,
}: {
  className?: string;
  inactive: boolean;
}) =>
  inactive ? (
    <InboxIcon style={{ fill: "#475467" }} className={className} />
  ) : (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V16H8.56C9.25 17.19 10.53 18 12.01 18C13.49 18 14.76 17.19 15.46 16H19V19ZM19 14H14.01C14.01 15.1 13.11 16 12.01 16C10.91 16 10.01 15.1 10.01 14H5V5H19V14Z"
        fill="#19186B"
      />
      <circle cx="19" cy="6" r="4.5" fill="#DA5656" stroke="white" />
    </svg>
  );
export default InboxActive;
