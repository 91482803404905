import { RenewalOfferFragment } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeRenewalOffers(
  input: WithoutNulls<RenewalOfferFragment[]>
) {
  return input.map((offer) => {
    const { decision } = offer;
    let decisionInfo;

    if (decision?.__typename === "ExternalRenewalOfferDecision") {
      decisionInfo = {
        externalDecision: true,
        decision: decision.decision,
        id: decision?.id,
        interestedTerm: decision.externalInterestedTerm,
        decidedAt: decision.externalDecidedAt,
        signedOnDate: decision.signedOnDate,
      };
    }
    if (decision?.__typename === "RenewalOfferDecision") {
      decisionInfo = {
        externalDecision: false,
        decision: decision.decision,
        interestedTerm: decision.interestedTerm,
        decidedAt: decision.decidedAt,
        eSignature: decision.eSignature,
        id: decision.id,
        signedOnDate: decision.signedOnDate,
      };
    }
    const mostRecentNeedSomethingElse = Array.isArray(offer.needSomethingElse)
      ? offer.needSomethingElse.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )[0]
      : null;

    const mostRecentNeedSomethingElseRequest =
      mostRecentNeedSomethingElse?.needs[0] ?? null;

    return {
      id: offer.id,
      workflowStatus: offer.workflowStatus,
      unit: {
        id: offer.lease?.unit?.id,
        name: offer.lease?.unit?.marketingName,
        building: offer.lease?.unit?.buildingName,
        communityName: offer.lease?.unit?.community.name,
      },
      // We need these items under determined status for sorting on mui grid
      determinedStatus: {
        lastViewedAt: offer.lastViewedAt,
        decisionType: decisionInfo?.decision,
        decidedAt: decisionInfo?.decidedAt,
        eSignature: decisionInfo?.eSignature,
        signedOnDate: decisionInfo?.signedOnDate,
        mostRecentNeedSomethingElseRequest,
        workflowStatus: offer.workflowStatus,
        unitId: offer.lease?.unit?.id,
        needSomethingElseResolvedAt: mostRecentNeedSomethingElse?.resolvedAt,
      },
      mostRecentNeedSomethingElseRequest,
      decision: {
        externalDecision: decisionInfo?.externalDecision,
        type: decisionInfo?.decision,
        interestedTerm: decisionInfo?.interestedTerm,
        decidedAt: decisionInfo?.decidedAt,
        signedOnDate: decisionInfo?.signedOnDate,
        unitUpgrade: offer.unitUpgrade,
        moveOutInfo: {
          moveOutDate: offer.lease?.moveOutInfo?.moveOutDate,
        },
        eSignature: decisionInfo?.eSignature,
        unit: {
          building: offer.lease?.unit?.buildingName,
        },
      },
      lease: {
        id: offer.lease?.id,
        noticeDate: offer.lease?.noticeDate,
        toDate: offer.lease?.toDate,
      },
      leaseToDate: offer.lease?.toDate,
      community: offer.lease?.unit?.community,
      renewalProfileInfo: {
        residentActions: offer.residentActions,
        likelihoodToRenew: offer.likelihoodToRenew,
        lastReminderSentAt: offer.lastReminderSentAt,
      },
    };
  });
}

export type RenewalOffers = ReturnType<typeof normalizeRenewalOffers>;
