import Metrics from "./partials/Metrics";
import DropdownFilters from "./partials/DropdownFilters";
import * as Styled from "./styles";
import useConnect from "./connect";
import StatusTables from "./partials/StatusTables";
import ActionItems from "./partials/ActionItems";
import ActionItemsInbox from "./partials/ActionItemsInbox";
import Leases from "./partials/Leases";

const RenewalOffers = () => {
  const {
    selectedWorkflowStatusFilters,
    handleWorkflowStatusFilterClick,
    isWorkflowStatusFilterActive,
    workflowStatusAndIconMap,
    metricsEnabled,
  } = useConnect();

  return (
    <Styled.Container>
      <Styled.Header title="Renewal Offers Overview" $fullWidth>
        {metricsEnabled && <ActionItemsInbox />}
      </Styled.Header>
      <DropdownFilters />
      <Styled.WidgetWrapper>
        {metricsEnabled ? <Metrics /> : <ActionItems />}

        <Leases />
      </Styled.WidgetWrapper>
      <Styled.RmsContainer>
        <Styled.Header size="small" title="Renewal Offers" $fullWidth={false}>
          {workflowStatusAndIconMap.map(
            ({ workflowStatusFilter, workflowStatusFilterTitleOverride }) => (
              <Styled.FilterItem
                key={workflowStatusFilter}
                label={
                  workflowStatusFilterTitleOverride || workflowStatusFilter
                }
                small
                variant={
                  isWorkflowStatusFilterActive(workflowStatusFilter)
                    ? "filled"
                    : "outlined"
                }
                onClick={() =>
                  handleWorkflowStatusFilterClick(workflowStatusFilter)
                }
              />
            )
          )}
        </Styled.Header>
        <StatusTables
          selectedWorkflowStatusFilters={selectedWorkflowStatusFilters}
        />
      </Styled.RmsContainer>
    </Styled.Container>
  );
};

export default RenewalOffers;
