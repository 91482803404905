import { RenewalOfferBatchQuery } from "@/graphql/generated";
import { TypedDocumentNode, gql } from "@apollo/client";

const GET_RENEWAL_OFFER_BATCH: TypedDocumentNode<RenewalOfferBatchQuery> = gql`
  query renewalOfferBatch($batchId: ID!) {
    renewalOfferBatch(batchId: $batchId) {
      __typename
      id
      metrics {
        name
        value
        icon
        tooltipCopy
      }
    }
  }
`;

export default GET_RENEWAL_OFFER_BATCH;
