import { AccordionDetails } from "@mui/material";
import { memo } from "react";

import * as Styled from "./styles";
import type { Props } from "./types";

function Accordion({
  children,
  disabled,
  expanded,
  ariaControls,
  accordionId,
  summary,
  className,
  onChange,
}: Props): JSX.Element {
  return (
    <Styled.Accordion
      className={className}
      disabled={disabled}
      defaultExpanded={expanded}
      onChange={onChange}
      data-testid="accordion"
    >
      <Styled.AccordionSummary
        aria-controls={ariaControls}
        id={accordionId}
        data-testid="accordion-summary"
      >
        {summary}
        {!disabled && <Styled.ArrowIcon data-testid="arrow-icon" />}
      </Styled.AccordionSummary>
      <AccordionDetails data-testid="accordion-details">
        {children}
      </AccordionDetails>
    </Styled.Accordion>
  );
}

export default memo(Accordion);
