import { Stepper, Step, StepIconProps } from "@mui/material";
import { Body1 } from "@/components/Typography";
import { RenewalOfferWorkflowStatus } from "@/graphql/generated";
import { ElementType } from "react";
import useRenewalSteps from "./useRenewalSteps";
import { Container, CustomConnector, CustomStepLabel } from "./styles";
import CustomStepIcon from "./CustomStepIcon";

const RenewalTimeline = ({
  status,
}: {
  status: RenewalOfferWorkflowStatus | undefined;
}) => {
  const steps = useRenewalSteps();

  const activeStep = {
    [RenewalOfferWorkflowStatus.InProgress]: 3,
    [RenewalOfferWorkflowStatus.Sent]: 2,
    [RenewalOfferWorkflowStatus.AwaitingDecision]: 2,
    [RenewalOfferWorkflowStatus.Completed]: 5,
  };

  return (
    <Container>
      <Stepper
        activeStep={activeStep[status ?? RenewalOfferWorkflowStatus.Sent]}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            {CustomStepIcon && (
              <CustomStepLabel
                StepIconComponent={CustomStepIcon as ElementType<StepIconProps>}
              >
                <Body1
                  $weight={
                    index ===
                    activeStep[status ?? RenewalOfferWorkflowStatus.Sent]
                      ? "medium"
                      : "regular"
                  }
                  $size="xs"
                >
                  {label}
                </Body1>
              </CustomStepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default RenewalTimeline;
