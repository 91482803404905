import styled from "@emotion/styled";
import { TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import Link from "next/link";
import PageHeader from "@/components/PageHeader";
import DefaultCard from "@/components/Card";
import UtilizeSpacer from "@/styles/theme-styles";
import defaultTheme from "@/styles/themes/default";
import Widget from "../Widget";

// Theme constants
const {
  colors: { grey300, grey600, night400, arctic25, black, error400, error600 },
} = defaultTheme.renewTheme;

// Common styles
const linkStyles = `
  cursor: pointer;
  text-decoration: underline;
  color: ${night400};
  font-weight: 500;
`;

// Layout components
export const Wrapper = styled.div<{ $noActionItems?: boolean }>`
  display: block;
  height: ${(props) => (props.$noActionItems ? "auto" : "inherit")};
`;

export const Container = styled(TableContainer)`
  height: 100%;
  display: block;
  border-radius: 16px;
  border: 1px solid ${grey300};
  box-shadow: none;
  ${UtilizeSpacer({
    options: [
      { size: "2xs", direction: [0] },
      { size: "xs", direction: [2] },
    ],
    type: "padding",
  })}
` as typeof TableContainer;

// Card components
export const EmptyStateCard = styled(DefaultCard)`
  background-color: ${arctic25};
  justify-content: center;
  max-width: 100%;
  width: 100%;
`;

export const NoOffersCard = styled(DefaultCard)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  margin: auto;

  p {
    color: ${grey600};
    font-family: "Mint Grotesk";
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
  }
`;

// Table components
export const TableHeader = styled(TableHead)`
  * {
    font-weight: 600;
  }
`;

export const Cell = styled(TableCell)<{ $urgent?: boolean; $header?: boolean }>`
  ${UtilizeSpacer({
    options: [
      { size: "xs", direction: [2] },
      { size: "0", direction: [4] },
      { size: "3xs", direction: [1, 3] },
    ],
    type: "padding",
  })}

  *:not(a) {
    color: ${(props) => (props.$urgent ? error600 : grey600)};
  }

  ${(props) => props.$header && `color: ${black};`}
`;

export const ClickableTableRow = styled(TableRow)`
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

// Link components
export const CellLink = styled(Link)`
  ${linkStyles}
  padding-bottom: 2px;
  display: block;
  font-size: 12px;
`;

export const ModalLink = styled.a`
  ${linkStyles}
  line-height: normal;
  padding-bottom: 2px;
  display: block;
`;

export const ActionLink = styled.button`
  color: ${error400};
  cursor: pointer;
  font-size: 12px;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: ${black};
  }
`;

// Icon components
export const InfoIcon = styled(InfoOutlinedIcon)`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const AttachEmailIcon = styled(AttachEmailOutlinedIcon)`
  height: 10px;
  width: 10px;
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "right",
    },
    type: "margin",
  })}
`;

// Widget components
export const WidgetContainer = styled(Widget)`
  min-width: 450px;
  height: 461px;

  div {
    max-height: 461px;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

// Other components
export const Header = styled(PageHeader)``;

export const Action = styled.span`
  width: fit-content;
  display: flex;
  font-weight: 500;
  align-items: center;
`;

export const ActionAmount = styled.div<{ $inactive: boolean }>`
  color: ${(props) => (props.$inactive ? grey600 : error400)};
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
`;

export const ModalContentWrapper = styled.div`
  text-align: left;
  color: ${grey600};
  ${UtilizeSpacer({
    specificOption: {
      size: "xs",
      direction: "top",
    },
    type: "margin",
  })}
`;

export const RequestItem = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "bottom",
    },
    type: "margin",
  })}

  span {
    color: ${black};
  }
`;
