import { RenewalOfferContext } from "@/context/renewal-offer/context";
import useActionItems from "@/graphql/hooks/actionItems/useActionItems";
import useResolveNeedSomethingElse from "@/graphql/hooks/resolveNeedSomethingElse/useResolveNeedSomethingElse";
import { useContext } from "react";

export default function useConnect() {
  const { renewalOfferState } = useContext(RenewalOfferContext);

  const { data, isLoading, error } = useActionItems({
    batchId: renewalOfferState?.batchId,
  });
  const { resolveNeedSomethingElse, loading: resolveLoading } =
    useResolveNeedSomethingElse(renewalOfferState?.batchId);
  const { totalCount, actionItems } = data || {};
  return {
    totalCount,
    actionItems,
    isLoading,
    error,
    resolveNeedSomethingElse,
    resolveLoading,
  };
}
