import { RenewalOfferBatch as Type } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeRenewalOfferBatch(
  batch: WithoutNulls<Partial<Type>>
) {
  return {
    metrics: batch.metrics,
  };
}
export type RenewalOfferBatch = ReturnType<typeof normalizeRenewalOfferBatch>;
