import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Body1 } from "@/components/Typography";
import ToolTip from "@/components/Tooltip";
import { MetricCardProps } from "./types";
import * as Styled from "./styles";

const MetricCard = ({ icon, title, metric, tooltip }: MetricCardProps) => (
  <Styled.Card>
    <Styled.IconWrapper>
      {icon}
      <Body1 $size="xs" $weight="medium">
        {title}
        {tooltip && (
          <ToolTip popperContent={tooltip}>
            <HelpOutlineIcon
              style={{ height: "8px", width: "8px", marginLeft: "4px" }}
            />
          </ToolTip>
        )}
      </Body1>
    </Styled.IconWrapper>
    <Body1 $size="md" $weight="bold">
      {metric}
    </Body1>
  </Styled.Card>
);

export default MetricCard;
