/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_RENEWAL_OFFER_BATCHES from "@/graphql/queries/renewalOfferBatches/getRenewalOfferBatches";
import normalizeRenewalOfferBatchesForRows from "@/model/RenewalOfferBatches";
import { withoutNulls } from "@/utils/withoutNulls";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

export default function useRenewalOfferBatches() {
  const { data: raw, loading, error } = useQuery(GET_RENEWAL_OFFER_BATCHES);
  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.renewalOfferBatches);
    if (data?.__typename === "RenewalOfferBatchesPayload")
      return normalizeRenewalOfferBatchesForRows(data.renewalOfferBatches);
    return null;
  }, [raw]);

  return { data: normalized, isLoading: loading, error };
}
