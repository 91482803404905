// useRenewalSteps.js

const useRenewalSteps = () => [
  "Offer created",
  "Offer sent",
  "Resident decision",
  "Review and finalize",
  "Complete",
];

export default useRenewalSteps;
