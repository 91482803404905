import Image from "next/image";
import IconHeaderCTA from "@/components/IconHeaderCTA";
import searching from "@/assets/images/illustrations/searching.png";
import Loader from "@/components/Loader";
import { GridRowParams } from "@mui/x-data-grid";
import { RowType } from "./types";
import { columns } from "./config";
import generateTableTitle from "./logic";
import useConnect from "./connect";
import * as Styled from "./styles";
import DetailDrawer from "../DetailDrawer";

const noOffersCardInfo = {
  content: "There are no current offers",
  illustration: {
    src: searching.src,
    alt: "No offers image",
  },
};

const TableComponent = ({
  selectedWorkflowStatusFilters,
}: {
  selectedWorkflowStatusFilters: Set<string>;
}) => {
  const {
    rows,
    isLoading,
    setSortModel,
    sortModel,
    workflowStatusAndIconMap,
    groupedRows,
    chipItemCounts,
    selectedOffer,
    onAccordionChange,
    residentActionsEnabled,
    onRowClick,
  } = useConnect();

  const NoOffersFallbackCard = (
    <Styled.NoOffersCard
      content={noOffersCardInfo.content}
      illustration={
        <Image
          src={noOffersCardInfo.illustration.src}
          alt={noOffersCardInfo.illustration.alt}
          width={74}
          height={61}
        />
      }
    />
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && rows && rows.length)
    return (
      <>
        {workflowStatusAndIconMap.map(
          ({
            workflowStatusFilter,
            workflowStatus,
            icon,
            workflowStatusFilterTitleOverride,
          }) =>
            (selectedWorkflowStatusFilters.has("All renewals") ||
              selectedWorkflowStatusFilters.has(workflowStatusFilter)) &&
            icon && (
              <Styled.Grid
                onAccordionChange={(_e, expanded) =>
                  onAccordionChange(expanded, workflowStatus)
                }
                key={workflowStatusFilter}
                tableTitle={generateTableTitle(
                  icon,
                  (workflowStatusFilterTitleOverride || workflowStatusFilter) +
                    (!groupedRows[workflowStatus].length ? ` (no offers)` : ""),
                  chipItemCounts[workflowStatus]?.actionItemCount,
                  chipItemCounts[workflowStatus]?.needSomethingElseCount
                )}
                accordion
                accordionOpen
                data={{
                  columns: columns({ residentActionsEnabled }),
                  rows: groupedRows[workflowStatus] || [],
                }}
                rowClassName={(params) =>
                  `units-theme--${params.row.status as string}`
                }
                setSortModel={setSortModel}
                sortModel={sortModel}
                hidePagination={groupedRows[workflowStatus]?.length <= 100}
                noDataComponent={NoOffersFallbackCard}
                onRowClick={(params: GridRowParams<RowType>) => {
                  onRowClick(params);
                }}
              />
            )
        )}
        <DetailDrawer renewalOffer={selectedOffer} />
      </>
    );

  if (!isLoading && rows.length < 1)
    return (
      <IconHeaderCTA
        illustration={
          <Image
            src={noOffersCardInfo.illustration.src}
            alt={noOffersCardInfo.illustration.alt}
            width={241}
            height={200}
          />
        }
        header={noOffersCardInfo.content}
      />
    );

  return null;
};

export default TableComponent;
