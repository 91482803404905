import { Text } from "@/components/Typography";
import { determineNeedSomethingElseCopy } from "@/utils/determineNeedSomethingElseCopy";
import { ActionItemType } from "@/graphql/generated";
import useModal from "@/hooks/useModal";
import { useState } from "react";
import daysTillLeaseEndsGenerator from "@/utils/daysTillLeaseEndGenerator";
import { Popover } from "@mui/material";
import InboxActive from "@/assets/images/svgs/InboxActive";
import * as Styled from "./styles";
import useConnect from "./connect";
import { IndividualActionItem } from "../types";
import NSEModal from "../NSEModal";
import { ActionItem } from "./ActionItem";

const renderAction = (row: IndividualActionItem) => {
  const daysTillLeaseEnds = daysTillLeaseEndsGenerator(row.lease.toDate);

  if (row.type === ActionItemType.EsignedNoticeToVacate) {
    return (
      <ActionItem
        icon={<Styled.InfoIcon />}
        actionText="Countersign: Notice to vacate"
        daysTillLeaseEnds={daysTillLeaseEnds}
      />
    );
  }

  return (
    row.needSomethingElse.mostRecent && (
      <ActionItem
        icon={<Styled.AttachEmailIcon />}
        actionText={determineNeedSomethingElseCopy(
          row.needSomethingElse.mostRecent
        )}
        daysTillLeaseEnds={daysTillLeaseEnds}
      />
    )
  );
};

const ActionItemsInbox = () => {
  const { actionItems, totalCount, isLoading } = useConnect();
  const { isModalOpen, onOpenModal, onCloseModal } = useModal();
  const [selectedNSE, setSelectedNse] =
    useState<IndividualActionItem["needSomethingElse"]>();

  const sortedActionItems = actionItems
    ?.filter((item) => item.lease?.toDate)
    .sort(
      (a, b) =>
        new Date(a.lease?.toDate ?? 0).getTime() -
        new Date(b.lease?.toDate ?? 0).getTime()
    );

  const handleRowClick = (
    row: IndividualActionItem,
    event: React.MouseEvent
  ) => {
    // Don't trigger row click if clicking the cell link
    if ((event.target as HTMLElement).closest("a")) {
      return;
    }

    if (
      row.needSomethingElse.mostRecent &&
      row.type === ActionItemType.NeedSomethingElse
    ) {
      setSelectedNse(row.needSomethingElse);
      onOpenModal();
    } else {
      window.location.href = `/units/${
        row.unit.id || ""
      }/countersign?source=renewal-offers`;
    }
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <NSEModal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        selectedNSE={selectedNSE}
      />
      <Styled.ActionLink aria-describedby={id} onClick={handleClick}>
        <Styled.ActionAmount $inactive={totalCount === 0}>
          <InboxActive inactive={totalCount === 0} />
          {totalCount} action items
        </Styled.ActionAmount>
      </Styled.ActionLink>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            borderRadius: "16px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Styled.WidgetContainer
          noItems={
            !isLoading && actionItems?.length < 1
              ? { content: "No active action items" }
              : undefined
          }
          title={
            !isLoading && actionItems?.length < 1 ? "" : "Active action items"
          }
          isLoading={isLoading}
          tableHeaders={[]}
          tableBody={sortedActionItems?.map((row) => (
            <Styled.ClickableTableRow
              key={row.id}
              onClick={(e) => handleRowClick(row, e)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <Styled.Cell component="th" scope="row">
                <Styled.CellLink href={`/units/${row.unit.id || ""}`}>
                  {row.unit.buildingName && `${row.unit.buildingName} - `}
                  {row.unit.name}
                </Styled.CellLink>
                <Text style={{ fontSize: "10px", lineHeight: "normal" }}>
                  {row.unit.communityName}
                </Text>
              </Styled.Cell>
              <Styled.Cell
                $urgent={daysTillLeaseEndsGenerator(row.lease.toDate) < 15}
              >
                {renderAction(row)}
              </Styled.Cell>
            </Styled.ClickableTableRow>
          ))}
        />
      </Popover>
    </>
  );
};

export default ActionItemsInbox;
