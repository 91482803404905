import styled from "@emotion/styled";
import SingleSelect from "../Select";

export const Select = styled(SingleSelect)`
  min-width: 250px;
  border-radius: 8px;
  margin-right: 10px;
  // Ensure aria-labelledby is passed to the underlying select element
  &[aria-labelledby] {
    aria-labelledby: inherit;
  }
`;
