/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */

import { RenewalOfferWorkflowStatus } from "@/graphql/generated";
import InProgress from "@/assets/images/svgs/InProgress";
import AwaitingDecision from "@/assets/images/svgs/AwaitingDecision";
import * as Styled from "./styles";

export const workflowStatusAndIconMap = [
  {
    workflowStatusFilter: "All renewals",
    workflowStatus: null,
    icon: null,
  },
  {
    workflowStatusFilter: "In progress",
    workflowStatus: RenewalOfferWorkflowStatus.InProgress,
    icon: <InProgress />,
  },
  {
    workflowStatusFilter: "Awaiting Decision",
    // workflowStatusFilterTitleOverride was added in order to allow us to change the displayed copy
    // in the status pills and table headers without changing the underlying workflow status on the backend
    // (which may be worth doing at a later date)
    workflowStatusFilterTitleOverride: "Viewed Offers, Undecided",
    workflowStatus: RenewalOfferWorkflowStatus.AwaitingDecision,
    icon: <AwaitingDecision />,
  },
  {
    workflowStatusFilter: "Sent",
    workflowStatus: RenewalOfferWorkflowStatus.Sent,
    icon: <Styled.SentIcon />,
  },
  {
    workflowStatusFilter: "Complete",
    workflowStatus: RenewalOfferWorkflowStatus.Completed,
    icon: <Styled.CompleteIcon />,
  },
];
