import { TableRow } from "@mui/material";
import { Body1 } from "@/components/Typography";
import { determineNeedSomethingElseCopy } from "@/utils/determineNeedSomethingElseCopy";
import ToolTip from "@/components/Tooltip";

import { ActionItemType } from "@/graphql/generated";
import useModal from "@/hooks/useModal";
import { useState } from "react";
import daysTillLeaseEndsGenerator from "@/utils/daysTillLeaseEndGenerator";
import * as Styled from "./styles";
import useConnect from "./connect";
import { ActionProps, IndividualActionItem } from "../types";
import NSEModal from "../NSEModal";

const ActionComponent = ({
  tooltipContent,
  actionText,
  icon,
  daysTillLeaseEnds,
}: ActionProps) => {
  const wrapper = (children: JSX.Element) =>
    tooltipContent ? (
      <ToolTip popperContent={tooltipContent}>{children}</ToolTip>
    ) : (
      <>{children}</>
    );

  return (
    <>
      <Body1 $size="xs" $weight="medium">
        {wrapper(
          <Styled.Action>
            {icon}
            {actionText}
          </Styled.Action>
        )}
      </Body1>
      <Body1 $size="2xs">
        {daysTillLeaseEnds < 0
          ? `Lease ended ${Math.abs(daysTillLeaseEnds)} ${
              Math.abs(daysTillLeaseEnds) <= 1 ? "day" : "days"
            } ago`
          : `Lease ends in ${daysTillLeaseEnds} ${
              daysTillLeaseEnds <= 1 ? "day" : "days"
            }`}
      </Body1>
    </>
  );
};

const renderAction = (row: IndividualActionItem) => {
  if (row.type === ActionItemType.EsignedNoticeToVacate) {
    return (
      <ActionComponent
        icon={<Styled.InfoIcon />}
        tooltipContent="Countersign and finalize the submitted notice to vacate"
        actionText="Countersign: Notice to vacate"
        daysTillLeaseEnds={daysTillLeaseEndsGenerator(row.lease.toDate)}
      />
    );
  }
  if (row.needSomethingElse.mostRecent) {
    return (
      <ActionComponent
        icon={<Styled.AttachEmailIcon />}
        actionText={determineNeedSomethingElseCopy(
          row.needSomethingElse.mostRecent
        )}
        daysTillLeaseEnds={daysTillLeaseEndsGenerator(row.lease.toDate)}
      />
    );
  }
  return null;
};

const ActionItems = () => {
  const { actionItems, totalCount, isLoading } = useConnect();

  // Sort actionItems by renewalOffer.lease.toDate
  const sortedActionItems = actionItems
    ?.filter((item) => item.lease?.toDate)
    .sort((a, b) => {
      const dateA = new Date(a.lease?.toDate ?? new Date());
      const dateB = new Date(b.lease?.toDate ?? new Date());
      return dateA.getTime() - dateB.getTime();
    });

  const { isModalOpen, onOpenModal, onCloseModal } = useModal();
  const [selectedNSE, setSelectedNse] =
    useState<IndividualActionItem["needSomethingElse"]>();

  const noActionItems = !isLoading && actionItems?.length < 1;

  return (
    <>
      <NSEModal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        selectedNSE={selectedNSE}
      />
      <Styled.WidgetContainer
        noItems={
          noActionItems ? { content: "No active action items" } : undefined
        }
        title="Active action items"
        headerContent={
          totalCount && (
            <Styled.ActionAmount>
              {totalCount > 25
                ? `${actionItems.length} of ${totalCount}`
                : totalCount}
            </Styled.ActionAmount>
          )
        }
        isLoading={isLoading}
        tableHeaders={[
          <Styled.Cell $header key={0}>
            Unit #
          </Styled.Cell>,
          <Styled.Cell $header key={1}>
            Status
          </Styled.Cell>,
          <Styled.Cell key={2} />,
        ]}
        tableBody={sortedActionItems?.map((row) => (
          <TableRow
            key={row.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <Styled.Cell component="th" scope="row">
              <Styled.CellLink href={`/units/${row.unit.id || ""}`}>
                {row.unit.buildingName && `${row.unit.buildingName} - `}
                {row.unit.name}
              </Styled.CellLink>
              <Body1 $size="2xs">{row.unit.communityName}</Body1>
            </Styled.Cell>
            <Styled.Cell
              $urgent={daysTillLeaseEndsGenerator(row.lease.toDate) < 15}
            >
              {renderAction(row)}
            </Styled.Cell>
            <Styled.Cell>
              {row.needSomethingElse.mostRecent &&
              row.type === ActionItemType.NeedSomethingElse ? (
                <Styled.ModalLink
                  onClick={() => {
                    if (
                      row.needSomethingElse.mostRecent &&
                      row.type === ActionItemType.NeedSomethingElse
                    ) {
                      setSelectedNse(row.needSomethingElse);
                      onOpenModal();
                    }
                  }}
                >
                  Resolve action item
                </Styled.ModalLink>
              ) : (
                <Styled.CellLink
                  href={`/units/${
                    row.unit.id || ""
                  }/countersign?source=renewal-offers`}
                >
                  Resolve action item
                </Styled.CellLink>
              )}
            </Styled.Cell>
          </TableRow>
        ))}
      />
    </>
  );
};

export default ActionItems;
