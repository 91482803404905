import defaultTheme from "@/styles/themes/default";
import UtilizeSpacer from "@/styles/theme-styles";
import styled from "@emotion/styled";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 167px;
  height: 92.5px;
  border-radius: 8px;
  border: 1px solid ${defaultTheme.renewTheme.colors.grey300};
  ${UtilizeSpacer({
    options: [
      {
        size: "3xs",
        direction: [0],
      },
    ],
    type: "padding",
  })}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  svg {
    margin-right: 4px;
    height: 12px;
    width: 12px;
  }
`;
