import { TableRow } from "@mui/material";
import { Body1 } from "@/components/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ToolTip from "@/components/Tooltip";
import Loader from "@/components/Loader";
import useConnect from "./connect";
import * as Styled from "./styles";

const Leases = () => {
  const { leases, isLoading } = useConnect();

  return isLoading ? (
    <Loader />
  ) : (
    <Styled.WidgetContainer
      title="Leases without renewal offers"
      noItems={
        !leases || leases.length < 1
          ? { content: "No leases without renewal offers" }
          : undefined
      }
      headerContent={
        <ToolTip
          style={{ marginLeft: "auto" }}
          popperContent="This list shows leases without renewal offers. Note: Month-to-month leases are excluded."
        >
          <HelpOutlineIcon sx={{ height: "16px", width: "16px" }} />
        </ToolTip>
      }
      isLoading={false}
      tableHeaders={[
        <Styled.Cell $header key={0}>
          Unit #
        </Styled.Cell>,
        <Styled.Cell $header key={1}>
          Lease end date
        </Styled.Cell>,
      ]}
      tableBody={leases?.map((row) => (
        <TableRow
          key={row.unitName}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <Styled.Cell component="th" scope="row">
            <Styled.CellLink href={`/units/${row.unitId || ""}`}>
              {row.unitName}
            </Styled.CellLink>
            <Body1 $size="2xs">{row.communityName}</Body1>
          </Styled.Cell>
          <Styled.Cell $urgent={row.daysTillLeaseEnd < 15}>
            <Body1 $size="xs" $weight="medium">
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                {row.daysTillLeaseEnd < 15 && (
                  <AccessTimeIcon
                    sx={{
                      height: "10px",
                      width: "10px",
                      marginRight: "4px",
                    }}
                  />
                )}
                {row.leaseEndDate}
              </div>
            </Body1>
            <Body1 $size="2xs">
              {row.daysTillLeaseEnd < 0
                ? `Lease ended ${Math.abs(row.daysTillLeaseEnd)} ${
                    Math.abs(row.daysTillLeaseEnd) <= 1 ? "day" : "days"
                  } ago`
                : `Lease ends in ${row.daysTillLeaseEnd} ${
                    row.daysTillLeaseEnd <= 1 ? "day" : "days"
                  }`}
            </Body1>
          </Styled.Cell>
        </TableRow>
      ))}
    />
  );
};
export default Leases;
