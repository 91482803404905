import React, { ElementType } from "react";
import * as MuiIcons from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material/styles";
import * as Styled from "./styles";

// Interface for icon styling options
interface IconOptions {
  size?: number | string;
  background?: boolean;
  border?: boolean;
  backgroundColor?: string;
  color?: string;
  stroke?: string;
  fill?: string;
  sx?: SxProps<Theme>;
}

const getDynamicIcon = (iconName: string): ElementType | null => {
  const cleanIconName = iconName.replace(/Icon$/, "");
  const IconComponent = (MuiIcons as Record<string, ElementType>)[
    cleanIconName
  ];
  return IconComponent || null;
};

// New function to get styled icon with options
const getStyledIcon = (iconName: string, options?: IconOptions) => {
  const IconComponent = getDynamicIcon(iconName);

  if (!IconComponent) {
    return null;
  }

  const {
    size,
    background,
    border,
    backgroundColor,
    color,
    stroke,
    fill,
    sx = {},
  } = options || {};

  const iconSx: SxProps<Theme> = {
    ...(size && { fontSize: size }),
    ...(color && { color }),
    ...(stroke && { stroke }),
    ...(fill && { fill }),
    ...sx,
  };

  // Create icon with styling applied correctly
  const IconWithStyles = React.createElement(IconComponent, { sx: iconSx });

  if (background || border) {
    return (
      <Styled.IconContainer
        background={background}
        border={border}
        backgroundColor={backgroundColor}
      >
        {IconWithStyles}
      </Styled.IconContainer>
    );
  }

  return IconWithStyles;
};

export { getDynamicIcon, getStyledIcon };
export default getDynamicIcon;
