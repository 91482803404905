/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeActionItems from "@/model/ActionItem";
import { WithoutNulls, withoutNulls } from "@/utils/withoutNulls";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import GET_ACTION_ITEMS from "@/graphql/queries/actionItems/getActionItems";
import { ActionItem } from "@/graphql/generated";

export default function useActionItems({ batchId }: { batchId?: string }) {
  const {
    data: raw,
    loading,
    error,
  } = useQuery(GET_ACTION_ITEMS, {
    variables: {
      batchId,
    },
    skip: !batchId,
    fetchPolicy: "cache-first",
  });

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.actionItems) as {
      totalCount: number;
      actionItems: ActionItem[];
    };
    return (
      data && {
        totalCount: data.totalCount,
        actionItems: normalizeActionItems(
          data.actionItems as WithoutNulls<ActionItem[]>
        ),
      }
    );
  }, [raw]);

  return { data: normalized, isLoading: loading, error };
}
