import { Text } from "@/components/Typography";
import * as Styled from "./styles";

export interface ActionProps {
  actionText: string;
  icon: JSX.Element;
  daysTillLeaseEnds: number;
}

export const ActionItem = ({
  actionText,
  icon,
  daysTillLeaseEnds,
}: ActionProps) => {
  const content = (
    <Styled.Action>
      {icon}
      {actionText}
    </Styled.Action>
  );

  const daysText =
    daysTillLeaseEnds < 0
      ? `Lease ended ${Math.abs(daysTillLeaseEnds)} ${
          Math.abs(daysTillLeaseEnds) <= 1 ? "day" : "days"
        } ago`
      : `Lease ends in ${daysTillLeaseEnds} ${
          daysTillLeaseEnds === 1 ? "day" : "days"
        }`;

  return (
    <>
      <Text $size="xs" $weight="medium">
        {content}
      </Text>
      <Text $size="2xs">{daysText}</Text>
    </>
  );
};
