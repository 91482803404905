import * as Styled from "./styles";

const generateTableTitle = (
  icon: React.ReactNode,
  text: string,
  actionItemCount?: number,
  residentMessageCount?: number
) => (
  <>
    {icon} {text}
    {actionItemCount && actionItemCount > 0 ? (
      <Styled.Highlight $size="2xs" $type="actions">
        {actionItemCount} action item{actionItemCount === 1 ? "" : "s"}
      </Styled.Highlight>
    ) : (
      ""
    )}
    {residentMessageCount && residentMessageCount > 0 ? (
      <Styled.Highlight $size="2xs" $type="messages">
        {residentMessageCount} resident message
        {residentMessageCount === 1 ? "" : "s"}
      </Styled.Highlight>
    ) : (
      ""
    )}
  </>
);

export default generateTableTitle;
