/* eslint-disable no-param-reassign */
import useCurrentUser from "@/graphql/hooks/user/useUser";
import { useState } from "react";
import { workflowStatusAndIconMap } from "./logic";

export default function useConnect() {
  const [selectedWorkflowStatusFilters, setSelectedWorkflowStatusFilters] =
    useState<Set<string>>(new Set(["All renewals"]));

  const { data: user } = useCurrentUser();
  const metricsEnabled = user?.enabledFeatures.includes("RMS_METRICS");

  const handleWorkflowStatusFilterClick = (filter: string) => {
    const newFiltersSet = new Set(selectedWorkflowStatusFilters);

    if (filter === "All renewals") {
      setSelectedWorkflowStatusFilters(new Set(["All renewals"]));
    } else {
      if (newFiltersSet.has(filter)) {
        newFiltersSet.delete(filter);
      } else {
        newFiltersSet.add(filter);
      }

      if (newFiltersSet.size === 0 || newFiltersSet.size === 4) {
        setSelectedWorkflowStatusFilters(new Set(["All renewals"]));
      } else {
        newFiltersSet.delete("All renewals");
        setSelectedWorkflowStatusFilters(newFiltersSet);
      }
    }
  };

  const isWorkflowStatusFilterActive = (filter: string) =>
    selectedWorkflowStatusFilters.has(filter);

  return {
    selectedWorkflowStatusFilters,
    handleWorkflowStatusFilterClick,
    isWorkflowStatusFilterActive,
    workflowStatusAndIconMap,
    metricsEnabled,
  };
}
