import { RenewalOfferBatch } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeRenewalOfferBatches(
  input: WithoutNulls<Omit<RenewalOfferBatch, "metrics">>[]
) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return input.map((batch) => {
    // Convert the month number to a long month name
    const longMonth = monthNames[Number(batch.month) - 1];

    // Create a sortable date
    const date = new Date(`${longMonth} 1 ${batch?.year}`);
    return {
      id: batch.id,
      leaseEnd: {
        label: `${longMonth} ${batch.year}`,
        date: date.toISOString().split("T")[0], // Format the date as 'yyyy-mm-dd'
      },
      month: longMonth,
      year: batch.year,
      community: batch.community.name,
      communityId: batch.community.id,
      renewalOfferPdfsAvailable: batch.renewalOfferPdfsAvailable,
    };
  });
}
export type RenewalOfferBatches = ReturnType<
  typeof normalizeRenewalOfferBatches
>;
