/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_RENEWAL_OFFER_BATCH from "@/graphql/queries/renewalOfferBatch/getRenewalOfferBatch";
import normalizeRenewalOfferBatch from "@/model/RenewalOfferBatch";
import { withoutNulls } from "@/utils/withoutNulls";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

export default function useRenewalOfferBatch(batchId?: string) {
  const {
    data: raw,
    loading,
    error,
  } = useQuery(GET_RENEWAL_OFFER_BATCH, {
    variables: { batchId },
    skip: !batchId,
  });

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.renewalOfferBatch);
    return data ? normalizeRenewalOfferBatch(data) : null;
  }, [raw]);

  return { data: normalized, isLoading: loading, error };
}
