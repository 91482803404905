import { useRouter } from "next/router";
import { useEffect } from "react";
import { Filter } from "../types";

const useQueryManager = (filters: Filter) => {
  const router = useRouter();
  const { query, push, pathname } = router;

  useEffect(() => {
    // Initialize queryParams with the current query parameters from the URL
    const queryParams: { [key: string]: string | string[] | undefined } = {
      ...query,
    };

    const communityValue = filters.community;
    if (typeof communityValue === "string" && communityValue.trim() !== "") {
      queryParams.community = communityValue;
      queryParams.communityId = filters.communityId;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.community;
      delete queryParams.communityId;
      delete queryParams.leaseEndDate;
    }

    const leaseEndDateValue = filters.leaseEndDate;
    if (
      typeof leaseEndDateValue === "string" &&
      leaseEndDateValue.trim() !== ""
    ) {
      queryParams.leaseEndDate = leaseEndDateValue;
      queryParams.month = filters.month;
      queryParams.year = filters.year;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.leaseEndDate;
    }

    const batchIdValue = filters.batchId;
    if (typeof batchIdValue === "string" && batchIdValue.trim() !== "") {
      queryParams.batchId = batchIdValue;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.leaseEndDate;
      delete queryParams.batchId;
    }

    // Update the URL with the new query parameters without triggering a full page reload
    void push(
      {
        pathname,
        query: queryParams,
      },
      undefined,
      { shallow: true }
    );
  }, [filters, pathname]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useQueryManager;
